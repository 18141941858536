
import { Component, Vue } from 'vue-property-decorator'
import MapLocation from '@/components/mapLocation/Index.vue'
import { ElForm } from 'element-ui/types/form'
import { Info, ResourcesLocation } from '@/types/buildFacilities'
import { FileInfo } from '@/types/common'
import UploadFile from '@/components/uploadFile/Index.vue'

@Component({
  components: { MapLocation, UploadFile }
})

export default class BuildFacilitiesAdd extends Vue {
  private info: Info = {
    projectId: '',
    buildName: '',
    buildDate: '',
    description: '',
    resourceList: [],
    resourcesLocationList: [],
    isNetRed: ''
  }

  private rules = {
    projectId: [
      { required: true, message: '请选择项目', trigger: 'change' }
    ],
    buildName: [
      { required: true, message: '请输入设施名称', trigger: ['blur', 'change'] }
    ],
    buildDate: [
      { required: true, message: '请选择建设日期', trigger: 'change' }
    ],
    description: [
      { required: true, message: '请输入描述', trigger: ['blur', 'change'] }
    ],
    resourceList: [
      { required: true, message: '请上传图片', trigger: 'change' }
    ],
    resourcesLocationList: [
      { required: true, message: '请选择位置坐标', trigger: 'change' }
    ],
    isNetRed: [
      { required: true, message: '请选择网红景点', trigger: 'change' }
    ]
  }

  private submitShow = false
  private resourcesLocationList: ResourcesLocation[] | null = null
  get projectList () {
    return this.$store.state.projectList
  }

  created () {
    this.$route.params.buildId && this.getDetail()
  }

  getDetail () {
    this.$axios.get(this.$apis.buildFacilities.selectBuildFacilitiesByBuildId, {
      buildId: this.$route.params.buildId
    }).then(res => {
      this.info = res || {}
      this.resourcesLocationList = res.resourcesLocationList
    })
  }

  locationChange (list: ResourcesLocation[]) {
    this.info.resourcesLocationList = list
  }

  // 文件上传成功返回
  onSuccess (file: FileInfo) {
    this.info.resourceList.push(file)
  }

  imgRemove (index: number) {
    this.info.resourceList.splice(index, 1)
  }

  onSubmit () {
    (this.$refs.info as ElForm).validate(valid => {
      if (valid) {
        this.submitShow = true
        const url = this.$route.params.buildId ? this.$apis.buildFacilities.updateBuildFacilities : this.$apis.buildFacilities.insertBuildFacilities
        this.$axios.post(url, this.info).then(() => {
          this.$message.success('保存成功')
          this.$router.push({ name: 'buildFacilitiesList' })
        }).finally(() => {
          this.submitShow = false
        })
      }
    })
  }
}
